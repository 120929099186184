@import '../../../scss/media';

.btn_services {
   display: flex;
   justify-content: center;
   position: fixed;
   width: auto;
   height: auto;
   bottom: -50px;
   left: 50%;
   transform: translateX(-50%);
   z-index: 20;

   transition: .6s cubic-bezier(0.68, -0.55, 0.265, 1.55);

   &.active {
      bottom: 40px;
      bottom: clamp(1.875rem, -1.25rem + 3.125vw, 2.5rem);
      // 40px > 30px
      transition: .6s ease;

      @include desktop {
         bottom: 30px;
      }

      @include tablet {
         bottom: 20px;
      }
   }

   @include tablet {}

   a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;

      border-radius: 50px;
      background: rgba(255, 255, 255, 0.20);
      backdrop-filter: blur(25px);

      color: #FFF;
      font-size: 18px;
      font-size: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);
      // 18px > 16px
      font-weight: 500;
      line-height: 24px;
      line-height: clamp(1.375rem, 0.75rem + 0.625vw, 1.5rem);
      // 24px > 22px

      transition: .4s;

      @include hover {
         background: rgba(255, 255, 255, 0.60);
      }

      @include desktop {
         font-size: 16px;
         line-height: 22px;
      }

      @include tablet {
         font-size: 14px;
         line-height: 20px;
         padding: 8px 18px;
      }
   }
}