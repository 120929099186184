@import '../../../scss/media';

.breadcrumbs_stroke {
   padding: 30px 0;
   border-bottom: 1px solid #929191;

   @include desktop {
      padding: clamp(1rem, -3.375rem + 5.833vw, 1.875rem) 0;
   }

   @include tablet {
      padding: 16px 0;
   }

   &__wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 5px;

      li {
         display: flex;
         position: relative;
         column-gap: 5px;

         color: #848685;
         font-size: clamp(0.875rem, -0.375rem + 1.25vw, 1.125rem);
         font-style: normal;
         font-weight: 500;
         line-height: clamp(1.25rem, 0rem + 1.25vw, 1.5rem);

         &::after {
            content: '/';
            display: block;
            color: #848685;
            font-size: inherit;
            font-style: inherit;
            font-weight: inherit;
            line-height: inherit;
         }

         &:last-of-type {
            &::after {
               display: none;
            }
         }

         @include desktop {
            font-size: 14px;
            line-height: 20px;
         }

         a {
            color: inherit;
            font-size: inherit;
            font-style: inherit;
            font-weight: inherit;
            line-height: inherit;
            transition: .4s;

            @include hover {
               color: #fff;
            }
         }

         span {
            color: inherit;
            font-size: inherit;
            font-style: inherit;
            font-weight: inherit;
            line-height: inherit;
         }
      }
   }
}