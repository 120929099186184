@import '../../scss/media.scss';

.header {
   padding: 16px 0;
   border-bottom: 1px solid #929191;
   background: #252323;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 99;
   transition: .6s;

   @include desktop {
      padding: 12px 0 11px 0;
   }

   @include tablet {
      padding: 15px 0 14px 0;
   }

   &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
         display: flex;
         height: 30px;
         width: 207px;
         transition: .6s;

         @include desktop {
            width: 138px;
            height: 20px;
         }

         svg,
         img {
            width: auto;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
         }
      }

      .header_r {
         display: flex;
         align-items: center;
         column-gap: 40px;

         @include desktop {
            column-gap: 40px;
         }

         @include tablet {
            display: flex;
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            background: #252323;
            height: 100dvh;
            z-index: 99;
            flex-direction: column-reverse;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 0 17px;

            transition: .4s;

            &::after {
               content: '';
               display: block;
               width: 100%;
               height: 1px;
               background-color: #929191;
               position: absolute;
               top: 49px;
               left: 0px;
            }

         }

         &.active {
            @include tablet {
               left: 0px;
            }
         }

         .header_btns {
            display: flex;
            align-items: center;
            column-gap: 10px;
         }

         .header_sign {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            padding: 6px 17px 8px 17px;
            border: 1px solid #FFF;
            position: relative;
            overflow: hidden;
            clip-path: inset(-1px -1px -1px -1px);

            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            transition: .45s;

            &.white {
               background: #fff;
               color: #2E2D2D;

               span {
                  position: relative;
                  z-index: 2;
               }

               .btn_bg {
                  background: #2E2D2D;
                  z-index: 1;
               }
            }

            .btn_bg {
               position: absolute;
               width: calc(100% + 10px);
               height: 0%;
               background: #FFF;
               position: absolute;
               bottom: -5px;
               left: -5px;
               transition: .45s;
               z-index: -1;
               border-radius: 50% 50% 0 0;
               // user-select: none;
               // pointer-events: none;
            }

            @include hover {
               color: #252323;

               &.white {
                  color: #fff;
               }

               .btn_bg {
                  height: calc(100% + 10px);
                  border-radius: 25% 25% 0px 0px;
               }
            }

            @include desktop {
               padding: 3px 17px;

               font-size: 14px;
               line-height: 22px;
            }

            @include tablet {
               margin: 10px 0;
            }
         }
      }

      nav {

         @include tablet {
            margin: 50px 0 20px 0;
            width: 100%;
            position: relative;
            flex: 1 1 auto;
            overflow-y: auto;
         }

         ul {
            @include tablet {
               flex-direction: column;
               justify-content: flex-start;
               align-items: flex-start;
               row-gap: 24px;
            }

            li {
               a {
                  @include tablet {
                     font-size: 28px;
                     line-height: 28px;
                  }
               }
            }
         }
      }

      .burger_btn {
         display: none;
         justify-content: center;
         align-items: center;
         height: 20px;
         width: 30px;
         position: relative;
         z-index: 100;

         @include tablet {
            display: flex;
         }

         span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 1px;
            background: #fff;
            position: relative;
            transform: translateY(-3px);

            transition: .3s;

            &::after {
               content: '';
               display: block;
               width: 100%;
               height: 1px;
               background: inherit;
               position: absolute;
               top: 7px;

               transition: inherit;
            }
         }

         &.active {
            span {
               transform: rotate(-45deg);

               &::after {
                  top: 0px;
                  transform: rotate(90deg);
               }
            }
         }
      }


   }

   &.active {
      padding: 9px 0;

      .logo {
         width: 138px;
         height: 20px;
      }

      nav ul li a {
         font-size: 14px;
         line-height: 14px;
      }

      .header_sign {
         font-size: 14px;
      }

      @include desktop {
         padding: 6px 0;

         .logo {
            width: 110px;
            height: 16px;
         }

         nav ul li a {
            font-size: 12px;
            line-height: 12px;
         }

         .header_sign {
            font-size: 12px;
            line-height: 20px;
            padding-top: 3px;
            padding-bottom: 3px;
         }
      }
   }
}