@import '../../../../scss/media.scss';

.sw_explore {
   overflow: visible;

   .slider_item {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      max-width: 300px;
      padding: 14px 0;
      border-top: 1px solid #929191;
      border-bottom: 1px solid #929191;

      @include mobile {
         max-width: 274px;
      }

      h3 {
         color: #FFF;

         font-size: 18px;
         font-style: normal;
         font-weight: 500;
         line-height: 24px;
      }

      .text {
         color: #848685;

         font-size: 14px;
         font-style: normal;
         font-weight: 400;
         line-height: 20px;
      }
   }
}