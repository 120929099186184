@import '../../../../scss/media';

.service_page_tech {
   margin-top: 120px;
   padding: clamp(7.5rem, 1.25rem + 6.25vw, 8.75rem) 0 140px 0;
   // 140px / 120px
   position: relative;
   background: linear-gradient(104deg, #525254 0%, #AAA 100%),
      url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='10' numOctaves='6' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");

   @include desktop {
      margin-top: clamp(3.125rem, -18.75rem + 29.167vw, 7.5rem);
      // 120px / 50px
      padding: clamp(3.125rem, -18.75rem + 29.167vw, 7.5rem) 0 clamp(3.125rem, -25rem + 37.5vw, 8.75rem) 0;
      // 120px / 50px 0 140px / 50px 0
   }

   @include tablet {
      margin-top: 50px;
      padding: 50px 0;
   }

   .tech_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 100px;
      row-gap: clamp(4.688rem, -3.125rem + 7.813vw, 6.25rem);

      @include desktop {
         row-gap: 75px;
         row-gap: clamp(2rem, -11.438rem + 17.917vw, 4.688rem);
      }

      @include tablet {
         row-gap: 32px;
      }

      &__title {
         text-align: center;

         max-width: 430px;

         @include desktop {
            max-width: clamp(16.875rem, -33.125rem + 66.667vw, 26.875rem);
            // 430px / 270px
         }

         @include tablet {
            max-width: 270px;
         }
      }

      &__list {
         display: flex;
         flex-wrap: wrap;
         align-items: flex-start;
         justify-content: center;
         row-gap: 40px;

         @include tablet {
            row-gap: 24px;
         }

         @include mobile {
            margin-left: 0px;
            margin-right: 0px;
         }

         &-item {
            display: flex;
            flex-direction: column;
            row-gap: 55px;
            row-gap: clamp(1.5rem, -8.188rem + 9.688vw, 3.438rem);
            flex: 1 1 33.3333333%;

            @include desktop {
               row-gap: 24px;
            }

            @include tablet {
               flex-direction: row;
               align-items: flex-start;
               column-gap: 16px;
               row-gap: 0px;
            }

            @include mobile {
               flex: 1 1 100%;
               border-bottom: 1px solid #fff;
               padding-bottom: 24px;
               padding-left: 0px;
               padding-right: 0px;
            }

            .head {
               display: flex;
               align-items: center;
               column-gap: 14px;

               &__icon {
                  justify-content: flex-start;
                  align-items: flex-start;
                  width: 100%;
                  width: 28px;
                  max-width: 28px;
                  height: 28px;
                  max-height: 28px;
                  overflow: hidden;

                  @include desktop {
                     width: 22px;
                     max-width: 22px;
                     height: 22px;
                     max-height: 22px;
                  }

                  @include tablet {
                     width: 18px;
                     max-width: 18px;
                     height: 18px;
                     max-height: 18px;
                  }

                  svg {
                     width: 100%;
                     height: 100%;
                     max-width: 100%;
                     max-height: 100%;
                  }
               }

               &__line {
                  flex: 1 1 auto;
                  background: #fff;
                  width: auto;
                  height: 1px;

                  @include tablet {
                     display: none;
                  }
               }
            }

            .text {
               color: #FFF;
               font-size: 24px;
               font-size: clamp(1.125rem, -0.75rem + 1.875vw, 1.5rem);
               font-style: normal;
               font-weight: 500;
               line-height: 30px;
               line-height: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);

               @include desktop {
                  font-size: 18px;
                  font-size: clamp(0.875rem, -0.375rem + 1.667vw, 1.125rem);
                  line-height: 24px;
                  line-height: clamp(1.25rem, 6.25rem + -6.667vw, 0.25rem);
               }

               @include tablet {
                  font-size: 14px;
                  line-height: 20px;
                  max-width: calc(100% - 35px);
               }
            }

            &.single {
               width: 100%;
               max-width: 685px;
               max-width: clamp(26.875rem, -52.813rem + 79.688vw, 42.813rem);
               row-gap: 40px;
               row-gap: clamp(1.5rem, -3.5rem + 5vw, 2.5rem);
               align-items: center;
               justify-content: center;
               border-bottom: none;
               flex-direction: column;

               @include desktop {
                  row-gap: 24px;
               }

               .head {
                  flex-direction: column;
                  row-gap: 50px;
                  width: 100%;

                  @include desktop {
                     row-gap: clamp(1.5rem, -6.625rem + 10.833vw, 3.125rem);
                  }

                  @include tablet {
                     flex-direction: column;
                     row-gap: 24px;
                  }

                  .head__line {
                     width: calc(100% - 60px);
                     display: flex;
                  }
               }

               .text {
                  text-align: center;
               }
            }
         }
      }
   }
}