@import '../../../../scss/media';

.service_page_spec {
   padding: 120px 0;
   padding: clamp(6.25rem, 0rem + 6.25vw, 7.5rem) 0;

   @include desktop {
      padding: 100px 0;
      padding: clamp(3.125rem, -12.5rem + 20.833vw, 6.25rem) 0;
   }

   @include tablet {
      padding: 50px 0;
   }

   .page_spec_wrap {
      display: flex;
      flex-direction: column;
      row-gap: 110px;

      @include desktop {
         row-gap: 180px;
         row-gap: clamp(3.438rem, -35.625rem + 52.083vw, 11.25rem);
      }

      @include tablet {
         row-gap: 50px;
      }

      .head {

         @include mobile {
            row-gap: 30px;
         }

         .head_right {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include mobile {
               justify-content: flex-start;
            }

            &.no_doctor {
               .head_right__text {
                  max-width: 100%;
               }
            }

            &__specialist {
               display: flex;
               align-items: center;
               justify-content: flex-start;
               flex-wrap: wrap;
               max-width: 50%;
               column-gap: 40px;
               column-gap: clamp(1.25rem, -5rem + 6.25vw, 2.5rem);

               @include desktop {
                  column-gap: 20px;
                  column-gap: clamp(0.625rem, -2.5rem + 4.167vw, 1.25rem);
               }

               @include tablet {
                  justify-content: flex-end;
                  column-gap: 10px;
               }

               @include mobile {
                  max-width: unset;
                  width: auto;
               }

               // &-item {
               //    display: flex;
               //    justify-content: center;
               //    align-items: center;
               //    border-radius: 50%;
               //    overflow: hidden;
               //    aspect-ratio: 1/1;
               //    max-width: 164px;
               //    max-width: clamp(8.625rem, 0.5rem + 8.125vw, 10.25rem);
               //    border: 12px solid #252323;
               //    border: clamp(0.625rem, 0rem + 0.625vw, 0.75rem) solid #252323;
               //    position: relative;
               //    flex: 1 1 clamp(5rem, -1.25rem + 6.25vw, 6.25rem);
               //    margin-left: calc(-0px - clamp(1.75rem, -3.875rem + 5.625vw, 2.875rem));

               //    @include desktop {
               //       max-width: 138px;
               //       max-width: clamp(6.25rem, -5.625rem + 15.833vw, 8.625rem);
               //       margin-left: -28px;
               //       margin-left: calc(-0px - clamp(2.5rem, 6.25rem + -5vw, 1.75rem));
               //       border: 10px solid #252323;
               //       border: clamp(0.375rem, -0.875rem + 1.667vw, 0.625rem) solid #252323;
               //       flex: 1 1 80px;
               //       flex: 1 1 clamp(3.75rem, -2.5rem + 8.333vw, 5rem);
               //    }

               //    @include tablet {
               //       border: 6px solid #252323;
               //       max-width: 86px;
               //       flex: 1 1 60px;
               //       margin-left: -50px;
               //    }

               //    &:last-of-type {
               //       margin-left: 0px;
               //    }

               //    img {
               //       width: 100%;
               //       height: 100%;
               //       object-fit: cover;
               //    }
               // }

               .avatar {
                  width: 100%;
                  max-width: 117px;
                  height: auto;
                  aspect-ratio: 1/1;
                  border-radius: 50%;
                  overflow: hidden;

                  @include desktop {
                     max-width: 117px;
                     max-width: clamp(3.25rem, -17.063rem + 27.083vw, 7.313rem);
                  }

                  @include tablet {
                     max-width: 52px;
                  }

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }

               .meta {
                  display: flex;
                  flex-direction: column;
                  row-gap: 2px;

                  .name {
                     color: #FFF;
                     font-size: 24px;
                     font-size: clamp(1.125rem, -0.75rem + 1.875vw, 1.5rem);
                     font-style: normal;
                     font-weight: 500;
                     line-height: 30px;
                     line-height: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);

                     @include desktop {
                        font-size: 18px;
                        font-size: clamp(0.875rem, -0.375rem + 1.667vw, 1.125rem);
                        line-height: 24px;
                        line-height: clamp(1.25rem, 0rem + 1.667vw, 1.5rem);
                     }

                     @include tablet {
                        font-size: 14px;
                        line-height: 20px;
                     }
                  }

                  .job {
                     color: #848685;
                     font-size: 24px;
                     font-size: clamp(1.125rem, -0.75rem + 1.875vw, 1.5rem);
                     font-style: normal;
                     font-weight: 400;
                     line-height: 30px;
                     line-height: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);

                     @include desktop {
                        font-size: 18px;
                        font-size: clamp(0.875rem, -0.375rem + 1.667vw, 1.125rem);
                        line-height: 24px;
                        line-height: clamp(1.25rem, 0rem + 1.667vw, 1.5rem);
                     }

                     @include tablet {
                        font-size: 14px;
                        line-height: 20px;
                     }
                  }
               }
            }

            &_top {
               display: flex;
               align-items: center;
               justify-content: flex-start;
               column-gap: 40px;

               @include mobile {
                  justify-content: flex-start;
                  flex-wrap: nowrap;
               }
            }

            &__text {
               color: #fff;
               font-size: 24px;
               font-size: clamp(1.125rem, -0.75rem + 1.875vw, 1.5rem);
               font-style: normal;
               font-weight: 400;
               line-height: 30px;
               line-height: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);

               max-width: 50%;

               @include desktop {
                  font-size: 18px;
                  font-size: clamp(0.875rem, -0.375rem + 1.667vw, 1.125rem);
                  line-height: 24px;
                  line-height: clamp(1.25rem, 0rem + 1.667vw, 1.5rem);
               }

               @include tablet {
                  font-size: 14px;
                  line-height: 20px;
               }

               @include mobile {
                  width: auto;
                  flex: 1 1 auto;
                  max-width: 300px;
               }
            }

            &_warn {
               display: flex;
               align-items: center;
               padding: 20px 30px;
               padding: 20px clamp(1.25rem, -1.875rem + 3.125vw, 1.875rem);
               // 30px > 20px
               column-gap: 30px;
               border-radius: 4px;
               background: #2E2D2D;

               margin-top: 30px;

               @include desktop {
                  padding: 20px;
                  margin-top: clamp(1.25rem, -1.875rem + 4.167vw, 1.875rem);
                  // 30px > 20px
               }

               @include tablet {
                  margin-top: 20px;
               }

               .icon {
                  display: flex;
                  width: 30px;
                  width: clamp(1.25rem, -1.875rem + 3.125vw, 1.875rem);
                  // 30px > 20px
                  height: 30px;
                  height: clamp(1.25rem, -1.875rem + 3.125vw, 1.875rem);
                  // 30px > 20px

                  @include desktop {
                     width: 20px;
                     height: 20px;
                  }

                  svg {
                     width: 100%;
                     height: 100%;
                  }
               }

               &-text {
                  width: 100%;
                  max-width: calc(100% - 30px - 30px);
                  max-width: calc(100% - 30px - clamp(1.25rem, -1.875rem + 3.125vw, 1.875rem));
                  // 30px > 20px
                  color: #848685;
                  font-family: Gilroy;
                  font-size: 24px;
                  font-size: clamp(0.875rem, -2.25rem + 3.125vw, 1.5rem);
                  // 24px > 14px
                  font-weight: 500;
                  line-height: 30px;
                  line-height: clamp(1.25rem, -1.875rem + 3.125vw, 1.875rem);
                  // 30px > 20px

                  @include desktop {
                     max-width: calc(100% - 30px - 20px);
                     font-size: 14px;
                     line-height: 20px;
                  }
               }
            }
         }
      }

      .body_content {
         &__image {
            width: 100%;
            height: auto;
            border-radius: 4px;
            overflow: hidden;
            aspect-ratio: 291/308;

            @include tablet {
               display: none;
            }

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }

         .questions {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            height: 100%;
            row-gap: 100px;

            @include desktop {
               row-gap: 100px;
               row-gap: clamp(1.5rem, -22.25rem + 31.667vw, 6.25rem);
            }

            @include tablet {
               row-gap: 24px;
               justify-content: flex-start;
            }

            &__list {
               display: flex;
               flex-direction: column;
               width: 100%;

               .szh-accordion {
                  border-bottom: 1px solid #ccc;

                  &__item {
                     border-top: 1px solid #ccc;

                     &-btn {
                        width: 100%;
                        text-align: unset;
                     }

                     &-content {
                        transition: height .4s ease;

                        color: #848685;
                        font-size: 24px;
                        font-size: clamp(1.125rem, -0.75rem + 1.875vw, 1.5rem);
                        font-style: normal;
                        font-weight: 400;
                        line-height: 30px;
                        line-height: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);

                        @include desktop {
                           font-size: 18px;
                           font-size: clamp(0.875rem, -0.375rem + 1.667vw, 1.125rem);
                           line-height: 24px;
                           line-height: clamp(1.25rem, 0rem + 1.667vw, 1.5rem);
                        }

                        @include tablet {
                           font-size: 14px;
                           line-height: 20px;
                           max-width: 700px;
                        }
                     }

                     &-panel {
                        padding-top: 20px;

                        @include tablet {
                           padding-top: 10px;
                        }
                     }

                     &--expanded {

                        .icon {
                           transform: rotate(180deg);
                        }
                     }
                  }
               }

               &-item {
                  display: flex;
                  flex-direction: column;
                  border-top: 1px solid #929191;
                  padding-top: 20px;
                  padding-bottom: 20px;

                  &:last-of-type {
                     border-bottom: 1px solid #929191;
                  }

                  .title {
                     display: flex;
                     align-items: center;
                     justify-content: space-between;
                     column-gap: 15px;
                     width: 100%;

                     color: #FFF;
                     font-size: 24px;
                     font-size: clamp(1.125rem, -0.75rem + 1.875vw, 1.5rem);
                     font-style: normal;
                     font-weight: 400;
                     line-height: 30px;
                     line-height: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);

                     @include desktop {
                        font-size: 18px;
                        font-size: clamp(0.875rem, -0.375rem + 1.667vw, 1.125rem);
                        line-height: 24px;
                        line-height: clamp(1.25rem, 0rem + 1.667vw, 1.5rem);
                     }

                     @include tablet {
                        font-size: 14px;
                        line-height: 20px;
                     }

                     .icon {
                        display: flex;
                        transition: .4s;
                     }
                  }
               }
            }
         }
      }
   }
}