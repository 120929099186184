@import '../../../scss/media';

.error_404 {
   display: flex;
   min-height: calc(100dvh - 70px);
   align-items: center;
   padding-top: 100px;
   padding-bottom: 100px;

   .error_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 60px;
      position: relative;

      @include desktop {
         row-gap: 40px;
      }

      @include tablet {
         row-gap: 24px;
      }

      &__icon {
         display: flex;
         width: 100%;
         max-width: 80px;
         height: auto;
         aspect-ratio: 1/1;
         animation-name: iconRotate;
         animation-duration: 20s;
         animation-fill-mode: forwards;
         animation-iteration-count: infinite;
         animation-timing-function: linear;

         @include desktop {
            max-width: 60px;
         }

         @include tablet {
            max-width: 40px;
         }

         svg {
            width: 100%;
            height: 100%;
         }

         @keyframes iconRotate {
            to {
               transform: rotate(360deg);
            }
         }
      }

      &__title {

         color: #FFF;
         text-align: center;

         font-size: 80px;
         font-style: normal;
         font-weight: 500;
         line-height: 80px;
         letter-spacing: -2.4px;

         @include desktop {
            font-size: 50px;
            line-height: 46px;
            letter-spacing: -1px;
         }

         @include tablet {
            font-size: 48px;
            line-height: 48px;
            letter-spacing: -0.96px;
         }
      }

      &__text {
         max-width: 600px;
         text-align: center;

         color: #FFF;

         font-family: Gilroy;
         font-size: 24px;
         font-style: normal;
         font-weight: 500;
         line-height: 30px;

         @include desktop {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;

            max-width: 430px;

            margin-top: -10px;

            @include tablet {
               font-size: 14px;
               font-weight: 500;
               line-height: 20px;

               max-width: 340px;

               margin-top: 0px;
            }
         }
      }

      &__bg {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         border-radius: 616px;
         background: rgba(245, 235, 205, 0.30);
         filter: blur(150px);
         width: 616px;
         width: 100%;
         max-width: 616px;
         aspect-ratio: 1/1;
         height: auto;
         border-radius: 50%;
         overflow: hidden;
         user-select: none;
         pointer-events: none;

         @include desktop {
            max-width: 360px;
         }
      }
   }
}