/* ОБНУЛЕНИЕ */

/**
  Нормализация блочной модели
 */
*,
*::before,
*::after {
   box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
   margin: 0;
   padding: 0;
   border: 0;
   font-size: 100%;
   font: inherit;
   vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
   display: block;
}

body {
   line-height: 1;
}

ol,
ul {
   list-style: none;
}

blockquote,
q {
   quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
   content: '';
   content: none;
}

table {
   border-collapse: collapse;
   border-spacing: 0;
}

/**
     Упрощаем работу с изображениями
    */
img {
   display: block;
   max-width: 100%;
}

/**
     Наследуем свойства шрифт для полей ввода
    */
input,
textarea,
select,
button {
   background: none;
   outline: none;
   border: none;
   padding: 0;
   margin: 0;
   border-radius: 0px;
   font: inherit;
}

html {
   /**
       Пригодится в большинстве ситуаций
       (когда, например, нужно будет "прижать" футер к низу сайта)
      */
   height: 100%;
   /**
       Плавный скролл
      */
   scroll-behavior: smooth;
}

body {
   /**
       Пригодится в большинстве ситуаций
       (когда, например, нужно будет "прижать" футер к низу сайта)
      */
   min-height: 100%;
   /**
       Унифицированный интерлиньяж
      */
   line-height: 1.5;
}


div {
   margin: 0;
   padding: 0;
}

a {
   text-decoration: none;
}

svg,
svg path,
svg rect,
svg circle,
svg line {
   transition: .3s;
}

.icon {
   display: flex;
}