$desktop: 1599px;
$tablet: 1199px;
$mobile: 767px;

@mixin desktop {
   @media screen and (max-width: $desktop) {
      @content;
   }
}

@mixin tablet {
   @media screen and (max-width: $tablet) {
      @content;
   }
}

@mixin mobile {
   @media screen and (max-width: $mobile) {
      @content;
   }
}

@mixin hover {
   @media (hover: hover) {
      &:hover {
         @content;
      }
   }

   @media (hover: none) {
      &:active {
         @content;
      }
   }
}