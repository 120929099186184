@import '../../../../scss/media';

.service_page_item {
   display: flex;
   width: 100%;
   max-width: 33.33333%;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
   padding: 80px 54px 50px 54px;
   border-bottom: 1px solid #848685;
   border-right: 1px solid #848685;
   overflow: hidden;
   position: relative;

   &:nth-child(3n) {
      border-right: none;
   }

   @include desktop {
      padding: clamp(1.5rem, -16rem + 23.333vw, 5rem) clamp(0.625rem, -8.75rem + 12.5vw, 2.5rem) clamp(1.5rem, -3.5rem + 6.667vw, 2.5rem);
   }

   @include tablet {
      padding: 24px 10px;
   }

   @include mobile {
      max-width: 100%;
      border-right: none;
   }

   &__title {
      color: #FFF;
      text-align: center;
      font-size: clamp(1.875rem, -1.25rem + 3.125vw, 2.5rem);
      font-style: normal;
      font-weight: 500;
      line-height: clamp(2.25rem, -0.875rem + 3.125vw, 2.875rem);

      @include desktop {
         font-size: clamp(1.25rem, -1.875rem + 4.167vw, 1.875rem);
         line-height: clamp(1.5rem, -2.25rem + 5vw, 2.25rem);
      }

      @include tablet {
         font-size: 20px;
         line-height: 24px;
      }
   }

   &__text {
      color: #FFF;
      text-align: center;
      font-size: clamp(1.125rem, -0.75rem + 1.875vw, 1.5rem);
      font-style: normal;
      font-weight: 400;
      line-height: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);

      margin-top: 16px;

      max-width: 750px;

      @include desktop {
         font-size: clamp(0.875rem, -0.375rem + 1.667vw, 1.125rem);
         line-height: clamp(1.25rem, 0rem + 1.667vw, 1.5rem);
      }

      @include tablet {
         font-size: 14px;
         line-height: 20px;
      }

      @include mobile {
         max-width: 450px;
      }
   }

   &__cost {
      color: #848685;
      font-size: clamp(1.125rem, -0.75rem + 1.875vw, 1.5rem);
      font-style: normal;
      font-weight: 400;
      line-height: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);

      margin-top: 30px;

      @include desktop {
         font-size: clamp(0.875rem, -0.375rem + 1.667vw, 1.125rem);
         line-height: clamp(1.25rem, 0rem + 1.667vw, 1.5rem);
      }

      @include tablet {
         font-size: 14px;
         line-height: 20px;

         margin-top: 20px;
      }
   }

   &__btn {
      display: flex;
      flex: 1 1 auto;
      align-items: flex-end;
      height: auto;

      margin-top: 92px;

      @include desktop {
         max-width: 30px;
         margin-top: clamp(1.5rem, -19.75rem + 28.333vw, 5.75rem);
      }

      @include tablet {
         margin-top: 24px;
      }

      @include mobile {
         margin-top: 40px;
      }

      a {
         width: 100%;
         max-width: 40px;
         aspect-ratio: 1/1;
         height: auto;
      }

      svg {
         width: 100%;
         height: 100%;
      }
   }

   &__circle {
      display: flex;
      width: 420px;
      height: 420px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(245, 235, 205, 0.20);
      filter: blur(150px);
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity .3s;
      user-select: none;
      pointer-events: none;
   }

   &:hover {
      .service_page_item__circle {
         opacity: 1;
      }
   }

   @include hover {
      .service_page_item__btn svg rect {
         fill: #56DAB0;
      }

      .service_page_item__btn svg path {
         stroke: #fff;
      }
   }
}