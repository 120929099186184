@import '../../../scss/media';

.contacts {
   border-top: 1px solid #848685;

   .front_head__left {
      display: flex;
      flex-direction: column;
      height: 100%;

      .top {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         row-gap: 60px;
         position: relative;
         flex: 1 1 auto;
         padding: 60px 0;

         @include desktop {
            padding-top: 60px;
            padding-bottom: 60px;
            row-gap: 40px;
         }

         @include tablet {
            padding-top: 54px;
            padding-bottom: 54px;
            row-gap: 24px;
            justify-content: center;
         }

         @include mobile {
            padding-top: 120px;
            padding-bottom: 67px;
         }

         h1 {

            @include desktop {
               font-size: 50px;
               line-height: 46px;
               letter-spacing: -1px;
            }

            @include tablet {
               font-size: 48px;
               line-height: 48px;
               letter-spacing: -0.96px;
            }

            @include mobile {
               font-size: 50px;
               line-height: 46px;
               letter-spacing: -1px;
            }
         }

         .contact_social {
            display: flex;
            flex-wrap: wrap;
            column-gap: 14px;

            &__item {
               display: flex;
               width: 100%;
               max-width: 40px;
               height: auto;
               aspect-ratio: 1/1;

               @include desktop {
                  max-width: 30px;
               }

               @include hover {
                  svg path {
                     fill: #848685;
                  }
               }

               svg {
                  width: 100%;
                  height: 100%;
               }
            }
         }

         .text {
            @include desktop {
               margin-top: -10px;
            }

            @include tablet {
               margin-top: 0px;
            }
         }
      }

      .list {

         @include tablet {
            display: flex;
         }

         .item {
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            row-gap: 4px;

            padding: 24px 40px;
            cursor: revert;

            @include tablet {
               padding: 14px 12px;
               flex: 1 1 100%;
               border-right: none;
            }

            .title {
               color: #FFF;

               font-size: clamp(1rem, -1.5rem + 2.5vw, 1.5rem);
               line-height: clamp(1.25rem, -1.875rem + 3.125vw, 1.875rem);
               font-style: normal;
               font-weight: 500;

               @include desktop {
                  font-size: clamp(0.875rem, 0.25rem + 0.833vw, 1rem);
                  line-height: clamp(1.125rem, 0.5rem + 0.833vw, 1.25rem);
               }
            }

            .sub_title {
               color: #848685;
               font-size: clamp(1.125rem, -0.75rem + 1.875vw, 1.5rem);
               font-style: normal;
               font-weight: 500;
               line-height: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);

               @include desktop {
                  font-size: clamp(0.875rem, -0.375rem + 1.667vw, 1.125rem);
                  font-weight: 400;
                  line-height: clamp(1.25rem, 0rem + 1.667vw, 1.5rem);
               }

               @include tablet {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
               }
            }
         }

         .item_wide {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 100%;

            .item {
               @include tablet {
                  flex: 1 1 50%;

                  &:nth-child(2n - 1) {
                     border-right: 1px solid #929191;
                  }
               }
            }
         }
      }
   }

   .col-md-6.image {
      position: relative;
   }

   .front_head__right {
      width: 100%;
      height: calc(100dvh - 71px);
      min-height: 100%;

      @include desktop {
         height: calc(100dvh - 55px);
      }

      @include tablet {
         height: 100%;
         aspect-ratio: 192/221;
         overflow: hidden;
         position: relative;
      }

      img {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         object-fit: cover;
         object-position: center;

         @include tablet {
            position: unset;
         }
      }
   }
}