@import '../../../scss/media.scss';

.modal_block {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   left: 0;
   background: rgba(46, 45, 45, 0.50);
   z-index: 999;
   opacity: 0;
   padding: 20px 40px;

   visibility: hidden;
   transition: .3s;
   transition-delay: .2s;

   @include tablet {
      padding: 0 17px;
   }

   @include mobile {
      padding: 0 12px;
   }

   .modal_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 600px;
      background: black;
      border-radius: 4px;
      background: #252323;
      padding: 40px 98px;
      max-width: 660px;
      position: relative;
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      opacity: 0;
      transform: translateY(20px);
      transition: .3s;
      transition-delay: 0s;

      &::-webkit-scrollbar {
         width: 0px;
      }

      @include tablet {
         padding: 30px 90px;
      }

      @include mobile {
         max-width: 450px;
         padding: 30px 20px;
      }

      &__close {
         position: absolute;
         top: 30px;
         right: 30px;
         display: flex;
         transition: .4s;

         @include mobile {
            top: 20px;
            right: 20px;
         }

         svg {
            user-select: none;
            pointer-events: none;
         }

         @include hover {
            opacity: .7;
         }
      }

      &__title {
         color: #FFF;
         text-align: center;

         font-size: 30px;
         font-style: normal;
         font-weight: 500;
         line-height: 36px;
      }

      .form_wrapper {
         width: 100%;
      }

      form {
         width: 100%;
      }

      &__form {
         display: flex;
         flex-direction: column;
         width: 100%;
         row-gap: 10px;
         margin-top: 30px;

         @include mobile {
            margin-top: 30px;
         }

         .modal_input {
            display: flex;
            align-items: center;
            width: 100%;
            height: 52px;
            position: relative;

            .error-indicator {
               display: flex;
               position: absolute;
               right: 20px;
               opacity: 0;
            }

            .icon {
               display: flex;
               position: absolute;
               right: 20px;
               height: 20px;
               width: 20px;
            }

            input:invalid+.icon::before,
            input:valid+.icon::before {
               content: '';
               width: 100%;
               height: 100%;
               opacity: 0;
               transition: .3s;
            }

            input {
               display: block;

               width: 100%;
               height: 100%;
               padding: 0 20px;
               border-radius: 8px;
               border: 1px solid #848685;

               color: #FFF;

               font-family: Gilroy;
               font-size: 14px;
               font-style: normal;
               font-weight: 500;
               line-height: 20px;

               transition: .4s;

               &:valid+.icon::before {
                  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Ccircle cx='10' cy='10' r='10' fill='%2309AF37'/%3E%3Cpath d='M6.66602 10.2733L8.35814 12.7676C8.92181 13.5984 10.1136 13.4966 10.5453 12.5807L13.3327 6.66699' stroke='white' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E");
                  display: flex;
                  opacity: 1;
               }

               &:focus-within:invalid+.icon::before {
                  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Ccircle cx='10' cy='10' r='10' fill='%23C53B3B'/%3E%3Cpath d='M7 13L13 7.00001' stroke='white' stroke-width='2' stroke-linecap='square'/%3E%3Cpath d='M7 7L13 13' stroke='white' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E");
                  display: flex;
                  opacity: 1;
               }

               &::placeholder {
                  color: var(--grey, #848685);

                  font-family: inherit;
                  font-size: inherit;
                  font-style: inherit;
                  font-weight: inherit;
                  line-height: inherit;
               }

               &:focus {
                  border-color: #fff;
               }
            }

         }

         .modal_area {
            width: 100%;
            height: 132px;

            textarea {
               width: 100%;
               height: 100%;
               resize: none;
               border: 1px solid #848685;
               border-radius: 8px;
               padding: 16px 20px;
               color: #FFF;

               font-family: Gilroy;
               font-size: 14px;
               font-style: normal;
               font-weight: 500;
               line-height: 20px;

               transition: .4s;

               &::placeholder {
                  color: var(--grey, #848685);

                  font-family: inherit;
                  font-size: inherit;
                  font-style: inherit;
                  font-weight: inherit;
                  line-height: inherit;
               }

               &:focus {
                  border-color: #fff;
               }

               &::-webkit-scrollbar-track {
                  background: transparent;
               }

               &::-webkit-scrollbar-thumb {

                  border-radius: 8px;
               }
            }

         }

         .modal_check {
            margin-top: 2px;

            .custom-checkbox {
               position: absolute;
               z-index: -1;
               opacity: 0;
            }

            .custom-checkbox+label {
               display: inline-flex;
               align-items: center;
               user-select: none;
               cursor: pointer;

               color: #848685;
               font-family: Gilroy;
               font-size: 12px;
               font-style: normal;
               font-weight: 500;
               line-height: normal;
            }

            .custom-checkbox+label::before {
               content: '';
               display: inline-block;
               width: 16px;
               height: 16px;
               flex-shrink: 0;
               flex-grow: 0;
               border: 1px solid #848685;
               border-radius: 4px;
               margin-right: 10px;
               background-repeat: no-repeat;
               background-position: center center;
               background-size: 100%;
            }

            .custom-checkbox:checked+label::before {
               border-color: #09AF37;
               background-color: #09AF37;
               background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='14' height='14' rx='3' stroke='%23848685' stroke-width='2'/%3E%3Crect width='16' height='16' rx='4' fill='%2309AF37'/%3E%3Cpath d='M5.59961 8.48466L6.95331 10.4801C7.40425 11.1448 8.35764 11.0633 8.70301 10.3306L10.9329 5.59961' stroke='white' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E");
            }
         }

         .modal_btn {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            width: 100%;

            @include mobile {
               margin-top: 10px;
            }

            input {
               display: inline-flex;
               justify-content: center;
               align-items: center;
               border-radius: 50px;
               background: #FFF;
               width: 100%;
               max-width: 310px;
               height: 44px;

               color: #252323;
               font-family: Gilroy;
               font-size: 18px;
               font-style: normal;
               font-weight: 500;
               line-height: 24px;

               cursor: pointer;

               transition: .4s;

               @include mobile {
                  font-size: 14px;
                  line-height: 20px;
                  max-width: 100%;
                  height: 38px;
               }
            }
         }
      }

      .circle {
         display: block;
         position: absolute;
         width: 300px;
         height: 300px;
         border-radius: 300px;
         background: rgba(245, 235, 205, 0.30);
         filter: blur(150px);
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         pointer-events: none;
         user-select: none;
         opacity: 0;
         transition: opacity .3s;

         @include tablet {
            opacity: 1;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
         }
      }

      &:hover {
         .circle {
            opacity: 1;
         }
      }

      .thx_wrap {
         display: flex;
         flex-direction: column;
         row-gap: 110px;
         align-items: center;
         padding-top: 140px;

         @include tablet {
            padding-top: 100px;
            row-gap: 80px;
         }

         @include mobile {
            padding-top: 40px;
            row-gap: 50px;
         }

         .modal_wrap__title {

            @include mobile {
               font-size: 24px;
               line-height: 30px;
            }
         }

      }
   }

   &.active {
      opacity: 1;
      visibility: visible;
      transition: .4s;
      transition-delay: 0s;

      .modal_wrap {
         opacity: 1;
         transform: translateY(0);
         transition-delay: .3s;
      }
   }
}