@import '../../../../scss/media.scss';

.discover {
   margin-top: 70px;

   @include desktop {
      margin-top: 64px;
   }

   @include tablet {
      margin-top: 50px;
   }

   &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 550px) {
         flex-direction: column;
         justify-content: flex-start;
         align-items: flex-start;
         row-gap: 20px;
      }

      .btn_ {
         background: #56DAB0;
         padding: 10px 20px;
         border-radius: 50px;

         color: #FFF;
         font-family: Gilroy;
         font-size: 18px;
         font-style: normal;
         font-weight: 500;
         line-height: 24px;

         background-blend-mode: plus-darker;
         position: relative;
         overflow: hidden;

         transition-property: background-color, color;
         transition-duration: .2s, .45s;
         transition-delay: 0s, 0s;

         @include desktop {
            padding: 10px 20px;

            font-size: 14px;
            line-height: 20px;
         }

         @include tablet {
            font-size: 14px;
            line-height: 20px;
         }

         span {
            position: relative;
            z-index: 2;
         }

         .btn_bg {
            position: absolute;
            width: 100%;
            height: 0%;
            background: #fff;
            position: absolute;
            bottom: 0px;
            left: 0;
            transition: .45s;
            z-index: 1;
            border-radius: 50% 50% 0 0;
         }

         @include hover {
            background: #fff;
            color: #252323;
            transition-duration: .1s, .45s;
            transition-delay: .2s, 0s;

            .btn_bg {
               height: 150%;
               border-radius: 25% 25% 0px 0px;
            }
         }
      }
   }

   &__wrap {
      display: flex;
      flex-wrap: wrap;
      --bs-gutter-x: 80px;
      margin-top: 62px;

      @include desktop {
         margin-top: 56px;
         margin-top: 24px;
      }

      @include tablet {
         --bs-gutter-x: 0px;
      }

      .wrap_item {
         width: 100%;
         display: flex;
         padding-left: 0px;
         padding-right: 0px;
         border-top: 1px solid #929191;


         .item {
            padding: 90px 40px 90px 40px;
            min-height: clamp(21.25rem, 0rem + 21.25vw, 25.5rem);
            border-right: 1px solid #929191;
            position: relative;
            overflow: hidden;
            flex: 1 1 auto;
            width: 33.333333%;
            transition: .6s;

            &:hover {
               .item__circle {
                  opacity: 1;
                  visibility: visible;
               }
            }

            @include desktop {
               padding: clamp(2.5rem, -10rem + 16.667vw, 5rem) 40px;
               min-height: clamp(15.625rem, -12.5rem + 37.5vw, 21.25rem);
            }

            @include tablet {
               padding: 14px 0;
            }

            @include mobile {
               display: flex;
               flex-direction: column;
               row-gap: 16px;
            }

            &:last-child {
               border-right: none;
            }

            &__title {
               color: #FFF;

               font-size: clamp(1.875rem, -1.25rem + 3.125vw, 2.5rem);
               font-style: normal;
               font-weight: 500;
               line-height: clamp(2.25rem, -0.875rem + 3.125vw, 2.875rem);

               @include desktop {
                  font-size: clamp(1.25rem, -2.5rem + 5vw, 1.875rem);
                  line-height: clamp(1.625rem, -2.125rem + 5vw, 2.25rem);
               }
            }

            &__text {
               margin-top: clamp(1.5rem, -1rem + 2.5vw, 2rem);

               color: #848685;

               font-size: clamp(1.125rem, -0.75rem + 1.875vw, 1.5rem);
               font-style: normal;
               font-weight: 400;
               line-height: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);
               height: 0px;
               opacity: 0;

               @include desktop {
                  margin-top: clamp(1rem, -1.5rem + 3.333vw, 1.5rem);
                  font-size: clamp(0.875rem, -0.375rem + 1.667vw, 1.125rem);
                  line-height: clamp(1.25rem, 0rem + 1.667vw, 1.5rem);
               }
            }

            &__circle {
               display: flex;
               width: 420px;
               height: 420px;
               border-radius: 50%;
               position: absolute;
               top: 0;
               left: 0;
               background: rgba(245, 235, 205, 0.20);
               filter: blur(150px);
               transform: translate(-50%, -50%);
               opacity: 0;
               visibility: hidden;
            }
         }

         &:hover {
            .item {
               flex: 1 1 auto;
               width: 25%;

               &:hover {
                  flex: 1 1 auto;
                  width: 50%;
               }
            }
         }
      }
   }
}