@import '../scss/media';

.animate_page {
   position: fixed;
   bottom: 0%;
   left: 0;
   z-index: 989999;
   background: #252323;

   padding: 0 20px;
   transform-origin: 50% 0%;
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0;
   width: 100%;
   height: 100%;
   top: 0%;
   left: 0%;
   transform: scale(1, 0);

   &.fadeIn {
      animation-name: animatePageIn;
      animation-duration: 1.4s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;

      svg {
         opacity: 0;
      }
   }

   &.fadeOut {
      animation-name: animatePageOut;
      animation-duration: 1.4s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;

      svg {
         opacity: 1;
         transition-delay: .15s;
      }
   }

   @keyframes animatePageIn {

      0% {
         transform: scale(1, 1);
         transform-origin: 50% 100%;
         border-radius: 0vw 0vw 0% 0%;
      }

      50% {
         transform: scale(1, 1);
         transform-origin: 50% 0%;
         border-radius: 0vw 0vw 0% 0%;
      }

      100% {
         transform-origin: 100% 0%;
         transform: scale(1, 0);
         border-radius: 0 0 100vw 100vw;
      }
   }

   @keyframes animatePageOut {
      0% {
         transform-origin: 50% 100%;
         transform: scale(1, 0);
         border-radius: 100dvw 100dvw 0 0;
      }

      50% {
         transform: scale(1, 1);
         transform-origin: 50% 100%;
         border-radius: 0vw 0vw 0% 0%;
      }

      100% {
         transform: scale(1, 1);
         transform-origin: 50% 100%;
         border-radius: 0vw 0vw 0% 0%;
      }
   }

   svg {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      max-width: 100%;
      transition: .6s;
      padding: 0 30px;
   }
}