@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-Medium.woff2') format('woff2');
   font-weight: 500;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-LightItalic.woff2') format('woff2');
   font-weight: 300;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-Light.woff2') format('woff2');
   font-weight: 300;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-BoldItalic.woff2') format('woff2');
   font-weight: bold;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-Extrabold.woff2') format('woff2');
   font-weight: bold;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-BlackItalic.woff2') format('woff2');
   font-weight: 900;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-Black.woff2') format('woff2');
   font-weight: 900;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-Thin.woff2') format('woff2');
   font-weight: 100;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-MediumItalic.woff2') format('woff2');
   font-weight: 500;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-ExtraboldItalic.woff2') format('woff2');
   font-weight: bold;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-RegularItalic.woff2') format('woff2');
   font-weight: normal;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-Semibold.woff2') format('woff2');
   font-weight: 600;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-Regular.woff2') format('woff2');
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-ThinItalic.woff2') format('woff2');
   font-weight: 100;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-SemiboldItalic.woff2') format('woff2');
   font-weight: 600;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-Heavy.woff2') format('woff2');
   font-weight: 900;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-HeavyItalic.woff2') format('woff2');
   font-weight: 900;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-UltraLight.woff2') format('woff2');
   font-weight: 200;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-Bold.woff2') format('woff2');
   font-weight: bold;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy-UltraLightItalic.woff2') format('woff2');
   font-weight: 200;
   font-style: italic;
   font-display: swap;
}