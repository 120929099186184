@import '../../../../scss/media.scss';

.front_about {
   position: relative;
   height: 110dvh;
   overflow: clip;
   transition: .5s;
   min-height: 870px;

   @include desktop {
      min-height: 754px;
   }

   @include tablet {
      margin-top: 50px;
      min-height: unset;
      height: auto;
   }

   @include mobile {
      height: auto;
      margin-top: 68px;
   }

   .front_about_wrap {
      position: sticky;
      top: 0px;
      width: 100%;
      padding: 40px 0;
      overflow: hidden;
      height: 100%;
      display: flex;
      align-items: center;

      @include desktop {
         padding: 100px 0 60px 0;
      }

      @include tablet {
         padding: 50px 0;
      }

      @include mobile {
         padding: 55px 0 50px 0;
      }
   }

   &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      &::after {
         content: '';
         display: block;
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(102, 102, 102, 0.00) 126.51%);
      }

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &::after {
      content: '';
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.39) 0%, rgba(102, 102, 102, 0.00) 100%);
      z-index: -1;

      @include tablet {
         display: block;
      }
   }

   .about_wrap {

      &__left {
         display: flex;
         align-items: center;
         flex-direction: column;
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         left: 40px;

         @media screen and (min-width: 2300px) {
            transform: translateY(-50%) scale(1.35);
         }

         @include tablet {
            position: relative;
            transform: unset;
            left: auto;
            top: auto;
            flex-direction: row;
         }

         @include mobile {
            column-gap: 10px;
         }

         .item_first {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 5px;
            row-gap: 20px;

            @include desktop {
               margin-bottom: 14px;
               row-gap: 20px;
            }

            @include tablet {
               margin-right: 30px;
               flex-direction: row;
               column-gap: 16px;
               row-gap: 0px;
               margin-bottom: 0px;
            }

            @include mobile {
               display: none;
            }

            .icon_big {
               height: 206px;

               @include desktop {
                  height: 130px;
               }

               @include tablet {
                  height: 20px;
               }

               svg {
                  width: auto;
                  height: 100%;
               }

               .desk {
                  @include tablet {
                     display: none;
                  }
               }

               .mob {
                  display: none;

                  @include tablet {
                     display: flex;
                  }
               }
            }

            .icon_small {
               width: 30px;
               height: 30px;
               max-width: 30px;
               max-height: 30px;
               animation-name: animateIcon;
               animation-duration: 10s;
               animation-timing-function: linear;
               animation-fill-mode: forwards;
               animation-iteration-count: infinite;

               @include tablet {
                  width: 20px;
                  height: 20px;
                  max-width: 20px;
                  max-height: 20px;
               }

               svg {
                  width: 100%;
                  height: 100%;
               }
            }

            @keyframes animateIcon {
               0% {
                  transform: rotate(0deg);
               }

               50% {
                  transform: rotate(180deg);
               }

               100% {
                  transform: rotate(360deg);
               }
            }
         }

         .item {
            display: flex;
            align-items: center;
            writing-mode: vertical-lr;

            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            position: relative;

            @include desktop {
               font-size: 12px;
               line-height: 12px;
            }

            @include tablet {
               writing-mode: unset;

               font-size: 12px;
               line-height: 12px;
            }

            @include mobile {
               column-gap: 10px;
            }

            &::before {
               content: '';
               display: block;
               width: 1px;
               height: 30px;
               background: #fff;
               margin: 16px 0;

               @include desktop {
                  margin: 14px 0;
               }

               @include tablet {
                  width: 30px;
                  height: 1px;
                  margin: 0 10px;
               }

               @include mobile {
                  margin: 0;
               }
            }
         }
      }

      &__top {
         justify-content: flex-end;

         @include tablet {
            justify-content: flex-start;
            margin-top: 32px;
            flex-direction: column-reverse;
         }

         @include mobile {
            margin-top: 35px;
         }

         .wrap {
            display: flex;
            flex-direction: column;
            row-gap: 30px;

            @include desktop {
               row-gap: clamp(1.25rem, -1.875rem + 4.167vw, 1.875rem);
            }

            @include tablet {
               row-gap: 20px;
            }

            .title {
               color: #fff;

               // font-size: 40px;
               font-size: clamp(1.875rem, -1.25rem + 3.125vw, 2.5rem);
               font-style: normal;
               font-weight: 500;
               // line-height: 46px;
               line-height: clamp(2.25rem, -0.875rem + 3.125vw, 2.875rem);
               position: relative;

               @include desktop {
                  // font-size: 30px;
                  font-size: clamp(1.625rem, 0.375rem + 1.667vw, 1.875rem);
                  // line-height: 36px;
                  line-height: clamp(2rem, 0.75rem + 1.667vw, 2.25rem);
               }

               @include tablet {
                  font-size: 28px;
                  line-height: 28px;
                  color: #fff;
               }

               &::before {
                  content: '';
                  display: block;
                  width: clamp(1.75rem, -0.75rem + 2.5vw, 2.25rem);
                  height: clamp(1.75rem, -0.75rem + 2.5vw, 2.25rem);
                  border: clamp(0.25rem, -0.375rem + 0.625vw, 0.375rem) solid #40DAC5;
                  border-radius: 50%;
                  position: absolute;
                  left: calc(0px - clamp(2.875rem, -3.375rem + 6.25vw, 4.125rem));
                  top: 3px;

                  @include desktop {
                     width: 28px;
                     height: 28px;
                     left: -46px;
                     border: 4px solid #40DAC5;
                  }
               }
            }

            .text {
               display: flex;
               flex-direction: column;
               row-gap: 40px;
               color: #fff;

               font-size: clamp(1.125rem, -0.75rem + 1.875vw, 1.5rem);
               font-style: normal;
               font-weight: 400;
               line-height: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);

               @include desktop {
                  font-size: clamp(1rem, 0.375rem + 0.833vw, 1.125rem);
                  line-height: clamp(1.375rem, 0.75rem + 0.833vw, 1.5rem);
                  row-gap: 25px;
               }

               @include tablet {
                  font-size: 14px;
                  line-height: 20px;
                  color: #fff;
                  row-gap: 10px;
               }
            }
         }
      }

      &__circles {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: flex-end;
         position: relative;
         width: 100%;
         max-width: 666px;
         aspect-ratio: 1/1;
         margin-left: auto;
         margin-right: auto;

         @media screen and (min-width: 2000px) {
            max-width: calc(100% - 37%);
         }

         @include desktop {
            max-width: 500px;
            margin-top: 44px;
         }

         @include tablet {
            margin-top: 32px;
            max-width: 351px;
         }

         .item {
            width: 100%;
            height: auto;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            position: absolute;


            color: #FFF;
            text-align: center;

            font-size: clamp(1.875rem, -1.25rem + 3.125vw, 2.5rem);
            font-style: normal;
            font-weight: 500;
            line-height: clamp(2.25rem, -0.875rem + 3.125vw, 2.875rem);

            .circle_title {
               opacity: 0;

               @include tablet {
                  opacity: 1 !important;
               }
            }

            @include desktop {
               font-size: 30px;
               line-height: 36px;
            }

            @include tablet {
               font-size: 18px;
               line-height: 24px;
               border: 1px solid #fff;
            }

            @include mobile {
               font-size: clamp(1rem, 0.273rem + 3.636vw, 1.125rem);
            }

            .cicle_svg {
               width: 100%;
               height: 100%;
               position: absolute;
               top: 0px;
               bottom: 0px;

               svg {
                  width: 100%;
                  height: 100%;
                  transition: 0s;

                  path {
                     stroke-dasharray: 0;
                     transition: 0s;
                  }
               }

               @include tablet {
                  display: none;
               }
            }

            &.circle1 {
               max-width: 345px;
               aspect-ratio: 1/1;
               bottom: 26px;
               justify-content: center;
               align-items: center;
               opacity: 1;

               @media screen and (min-width: 2000px) {
                  max-width: calc(100% - 47%);
               }

               @include desktop {
                  max-width: 260px;
                  bottom: 20px;
               }

               @include tablet {
                  max-width: 182px;
                  bottom: 14px;
               }

               @include mobile {
                  max-width: calc(100% - 170px);
               }
            }

            &.circle2 {
               max-width: 505px;
               aspect-ratio: 1/1;
               bottom: 13px;
               padding-top: 67px;

               @media screen and (min-width: 2000px) {
                  max-width: calc(100% - 24%);
               }

               @include desktop {
                  max-width: 380px;
                  padding-top: 48px;
                  bottom: 10px;
               }

               @include tablet {
                  max-width: 266px;
                  bottom: 7px;
                  padding-top: 30px;
               }

               @include mobile {
                  max-width: calc(100% - 85px);
               }
            }

            &.circle3 {
               max-width: 100%;
               aspect-ratio: 1/1;
               padding-top: 56px;

               @include desktop {
                  padding-top: 46px;
               }

               @include tablet {
                  padding-top: 33px;
               }
            }
         }
      }

   }
}