@import '../../../../scss/media.scss';

.space {
   position: relative;
   aspect-ratio: 16/8.6;
   overflow: hidden;
   background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);

   @include tablet {
      aspect-ratio: 256/163;
      margin-left: 0px;
      margin-right: 0px;
   }

   @include mobile {
      aspect-ratio: 125/163;
   }

   .container {
      height: 100%;
   }

   &__wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 40px;
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
      height: 100%;

      @include desktop {
         row-gap: 30px;
      }

      @include tablet {
         max-width: 574px;
         row-gap: 24px;
      }

      @include mobile {
         max-width: 450px;
      }

      h2 {
         text-align: center;
      }

      .text {
         color: #FFF;
         text-align: center;

         font-size: 24px;
         font-style: normal;
         font-weight: 400;
         line-height: 30px;

         @include desktop {
            font-size: 18px;
            line-height: 24px;
         }

         @include tablet {
            font-size: 18px;
            line-height: 24px;
         }

         @include mobile {
            font-size: 14px;
            line-height: 20px;
         }
      }

      .btn {
         margin-top: 10px;

         @include desktop {
            margin-top: 0px;
         }

         @include tablet {
            margin-top: 8px;
         }

         .btn_more {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 14px 26px;
            border-radius: 50px;
            background: #56DAB0;
            background-blend-mode: plus-darker;
            position: relative;
            overflow: hidden;

            color: #FFF;

            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;

            transition-property: background-color;
            transition-duration: .2s;
            transition-delay: 0s;

            @include desktop {
               padding: 10px 20px;

               font-size: 18px;
               line-height: 24px;
            }

            @include tablet {
               font-size: 14px;
               line-height: 20px;
            }

            span {
               position: relative;
               z-index: 2;
            }

            .btn_bg {
               position: absolute;
               width: 100%;
               height: 0%;
               background: #252323;
               position: absolute;
               bottom: 0px;
               left: 0;
               transition: .45s;
               z-index: 1;
               border-radius: 50% 50% 0 0;
            }

            @include hover {
               background: #252323;
               transition-duration: .1s;
               transition-delay: .2s;

               .btn_bg {
                  height: 150%;
                  border-radius: 25% 25% 0px 0px;
               }
            }
         }
      }
   }

   &__bg {
      position: absolute;
      bottom: -250px;
      left: 0;
      width: 100%;
      height: calc(100% + 250px);
      z-index: -1;

      @include tablet {
         height: 100%;
         bottom: auto;
         top: 0px;
         transform: translate(0px);
      }

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
}